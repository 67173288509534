<template>
  <div>
    <SmallNav></SmallNav>
    <div class="container">
      <div class="breakdown-holder">
        <div class="top-holder">
          <div>
            <img @click="back()" src="../assets/images/safe.svg" alt="back" />
            <span @click="back()">Back</span>
          </div>
        </div>

        <div class="body-holder">
          <!-- d-none d-md-block -->
          <div class="table-responsive d-none d-md-block">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Date</th>
                  <th scope="col">Repayment Bal.</th>
                  <th scope="col">Actual charges</th>
                  <th scope="col">Total Amount</th>
                  <th scope="col">Amount paid</th>
                  <th scope="col">Charges</th>
                  <th scope="col">Amount Left</th>
                </tr>
              </thead>
              <tbody v-for="(item, index) in breakdown" :key="index">
                <!-- <p>{{ json.parse(breakdown.monthlylogs) }}</p> -->
                <!-- <p>{{ panel }}</p> -->
                <tr>
                  <th scope="row" v-if="item.start_date_end_date != null">
                    {{ item.start_date_end_date }}
                  </th>
                  <th scope="row" v-else>{{ "00-00-0000" }}</th>
                  <td>₦{{ Number(item.gapfinance).toLocaleString() || 0 }}</td>
                  <td>
                    ₦{{ Number(item.actualcharge).toLocaleString() || 0 }}
                  </td>
                  <td>₦{{ Number(item.payable).toLocaleString() || 0 }}</td>
                  <td>₦{{ Number(item.amountpaid).toLocaleString() || 0 }}</td>
                  <td style="color: #2BC300;">
                    ₦{{ Number(item.charges).toLocaleString() || 0 }}
                  </td>
                  <td>
                    ₦{{ Number(item.balance).toLocaleString() || 0 }}
                    <span
                      style="cursor: pointer; padding-left: 3px;"
                      :id="index"
                      @click="panelToggle2(index)"
                      ><i
                        v-if="item.monthlylogscharles.length > 0"
                        class="fa fa-chevron-down"
                      ></i
                    ></span>
                  </td>
                </tr>

                <tr
                  v-show="panel2 === Number(index)"
                  v-for="(dataa, index2) in item.monthlylogscharles"
                  :key="index2"
                  class="otherPayment"
                >
                  <td>
                    {{ dataa.date }}
                  </td>
                  <td>
                    ₦{{ Number(dataa.amount).toLocaleString() }}
                  </td>
                </tr>
                <!-- </tr> -->
              </tbody>
            </table>
          </div>

          <div
            class="mobile-view d-block d-sm-none d-none d-sm-block d-md-none"
          >
            <div class="mobile-header row">
              <h6 class="col-sm-6 col-md-6">Repayment bal.</h6>
              <h6 class="col-sm-6 col-md-6">Date</h6>
            </div>
            <div
              class="mobile-body"
              :class="[panel === index ? '' : 'inActiveBodyMobile']"
              v-for="(item, index) in breakdown"
              :key="index"
            >
              <div
                :id="index"
                class="mobile-top row"
                @click="panelToggle(index)"
              >
                <p class="col-sm-6 col-md-6">
                  ₦{{ Number(item.gapfinance).toLocaleString() || 0 }}
                </p>
                <p class="col-sm-6 col-md-6" v-if="item.start_date_end_date != null">
                  {{ item.start_date_end_date }}
                  <span
                    ><i
                      class=""
                      :class="[
                        panel === index
                          ? 'fa fa-chevron-up'
                          : 'fa fa-chevron-down'
                      ]"
                    ></i
                  ></span>
                </p>
                <p class="col-sm-6 col-md-6" v-else>
                  {{ "00-00-0000" }}
                  <span
                    ><i
                      class=""
                      :class="[
                        panel === index
                          ? 'fa fa-chevron-up'
                          : 'fa fa-chevron-down'
                      ]"
                    ></i
                  ></span>
                </p>
              </div>
              <div class="mobile-others" v-if="panel === index">
                <p>
                  Charges:
                  <span>₦{{ Number(item.charges).toLocaleString() || 0 }}</span>
                </p>
                <p>
                  Total Amount:
                  <span>₦{{ Number(item.payable).toLocaleString() || 0 }}</span>
                </p>
                <p>
                  Amount paid:
                  <span
                    >₦{{ Number(item.amountpaid).toLocaleString() || 0 }}</span
                  >
                </p>
                <h6
                  v-if="item.monthlylogscharles.length > 0"
                  data-toggle="modal"
                  data-target="#morepayments"
                  @click="morePaymentsMobile = item.monthlylogscharles"
                >
                  More Payments
                </h6>
              </div>
            </div>
          </div>

          <!-- Modal for mobile More payments -->
          <div
            class="modal fade"
            id="morepayments"
            tabindex="-1"
            aria-labelledby="r&c"
            aria-hidden="true"
          >
            <div
              class="modal-dialog modal-dialog-centered"
              style="margin-bottom: 50px;"
            >
              <div class="modal-content">
                <div class="modal-header" style="border-bottom: none;">
                  <h5 class="modal-title title-modal" id="rc">
                    More Payments
                  </h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="table-responsive">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">Date</th>
                          <th scope="col">Amount paid</th>
                        </tr>
                      </thead>
                      <tbody
                        v-for="(more, index) in morePaymentsMobile"
                        :key="index"
                      >
                        <tr class="otherPayment">
                          <td>
                            {{ more.date }}
                          </td>
                          <td>
                            ₦{{ Number(more.amount).toLocaleString() }}
                          </td>
                        </tr>
                        <!-- </tr> -->
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Modal for mobile More payments ends -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SmallNav from "./SmallNav";
import { Service } from "../store/service";
const Api = new Service();
import moment from "moment";
export default {
  name: "SafePaymentBreakdown",
  // props: {
  //   properties
  // },

  components: {
    SmallNav
  },
  data() {
    return {
      breakdownPayment: [],
      breakdown: [],
      morePaymentsMobile: [],
      panel: "",
      panel2: ""
    };
  },
  //   created() {
  //     this.$root.$refs.PropertyManagementAgreement = this === "Property Management Agreement";
  //   },
  mounted() {
    this.breakdownPayment = Api.getPaymentBreakdown();
    console.log("Breakdown", this.breakdownPayment);
    // console.log("Mounted", this.panel2);
    if (this.breakdownPayment.length < 1) {
      this.$router.go(-1);
    } else {
      this.breakdown = Api.getPaymentBreakdown();
    }
    if (this.panel != "") {
      document.addEventListener("click", this.handleClickOutside);
    }
    if (this.panel2 != "") {
      console.log("added event cliked", this.panel2);
      document.addEventListener("click", this.handleClickOutside2);
    }
  },
  beforeDestroy() {
    // Remove the click event listener when the component is about to be destroyed
    document.removeEventListener("click", this.handleClickOutside);
    document.removeEventListener("click", this.handleClickOutside2);
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    panelToggle(y) {
      // this.panel = y;
      // document.addEventListener("click", this.handleClickOutside);
      if (y === this.panel) {
        this.panel = "";
        // console.log("Empty");
      } else {
        this.panel = y;
        // console.log("Contains");
      }
      //  document.addEventListener("click", this.handleClickOutside);
      // console.log("Toggle ciked", this.panel, y);
    },
    handleClickOutside(event) {
      // console.log("Event", event.target);
      const targetElement = document.getElementById(this.panel);
      // console.log("Handled click", this.panel);
      if (targetElement != null) {
        if (!targetElement.contains(event.target)) {
          // The click occurred outside the target element
          // Put your code here to handle clicks outside the element
          this.panel = "";
        } else {
          this.panel;
        }
      }
    },
    panelToggle2(x) {
      document.addEventListener("click", this.handleClickOutside2);
      if (x === this.panel2) {
        this.panel2 = "";
      } else {
        this.panel2 = x;
      }
    },
    handleClickOutside2(event) {
      const targetElement = document.getElementById(this.panel2);
      if (targetElement != null) {
        if (!targetElement.contains(event.target)) {
          // The click occurred outside the target element
          // Put your code here to handle clicks outside the element
          this.panel2 = "";
        } else {
          this.panel2;
        }
      }
    }
  },
  filters: {
    moment: function(date) {
      return moment(date).format("MMMM Do YYYY");
    }
  }
};
</script>

<style lang="scss" scoped>
$white: #ffffff;
$off-white: #fafafa;
$primary: #0033ea;
$offblack: #262626;
$limeGreen: #2bc300;
$grey: #555c74;

@font-face {
  font-family: "Gotham-bold";
  src: local("Gotham-bold"),
    url("../assets/fonts/shelta_fonts/Gotham\ Bold.otf") format("truetype");
}
@font-face {
  font-family: "Lato";
  src: local("Lato-Regular"),
    url("../assets/fonts/Lato/Lato-Regular.ttf") format("truetype");
}
.top-holder {
  display: flex;
  margin-top: 30px;
  margin-bottom: 30px;
  img {
    background: linear-gradient(#0077ff, #2a579f);
    padding: 6px;
    border-radius: 5px;
    margin-right: 6px;
  }
  div {
    color: $offblack;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
    cursor: pointer;
  }
}
.body-holder {
  background: $white;
  border-radius: 20px;
  padding: 24px 24px 40px 24px;
  // height: 100vh;
}

.otherPayment {
  td {
    border-top: none;
  }
}
.table-body-holder {
  display: flex;
  //   justify-content: space-between;
  padding: 16px;
  box-sizing: border-box;
}
thead {
  tr {
    background: #f9f9f9;
    border-radius: 4px;
  }
  th {
    border-top: none;
    border-bottom: none;
    text-align: left;
    color: $grey;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
    padding: 16px;
    white-space: nowrap;
  }
  th:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  th:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}
tbody {
  th,
  td {
    color: $grey;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    padding: 16px;
    text-align: left;
    white-space: nowrap;
  }
}
tbody {
  tr:first-child {
    th,
    td {
      border-top: none;
    }
  }
}

// Mobile view begins
.mobile-view {
}
.mobile-header {
  padding: 15px 0px;
  border-radius: 4px 4px 0px 0px;
  background: $off-white;
  flex-wrap: nowrap;
  white-space: nowrap;
  margin: 0;
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  h6 {
    color: $grey;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    // font-weight: 500;
    font-weight: bold;
    line-height: 150%;
    margin-bottom: 0;
  }
  h6:first-child {
    text-align: left;
  }
  h6:nth-child(2) {
    text-align: left;
  }
}
.mobile-body {
  background: #f7f7f7;
}
.inActiveBodyMobile {
  background: $white;
  border-bottom: 1.5px solid #eaecef;
}
.mobile-top {
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  cursor: pointer;
  flex-wrap: nowrap;
  padding: 15px 16px;
  p {
    text-align: left;
    color: $grey;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 0;
    span {
      padding-left: 10px;
    }
  }
  p:nth-child(2) {
    display: flex;
    justify-content: space-between;
    white-space: nowrap;
  }
}
.mobile-others {
  text-align: left;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 20px;
  // margin-bottom: 16px;
  border-bottom: 1.5px solid #eaecef;
  // transition: all .5s ease-in-out;
  // transition-duration: 0.5s;
  // transition-timing-function: ease-in;
  // transition-delay: 0.3s;

  p {
    color: $grey;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 16px;
  }
  h6 {
    color: $primary;
    font-family: Lato;
    text-decoration: underline;
    font-size: 16px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

.mobile-others {
  animation-duration: 1s;
  animation-name: slidein;
}
.mobile-others {
  animation-duration: 1s;
  animation-name: slideout;
}

@keyframes slidein {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slideout {
  100% {
    transform: translateY(0);
    opacity: 1;
  }
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
}

// Mobile view ends
</style>
